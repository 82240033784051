import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TestYourselfContent from "../components/test-yourself-content"



const TestYourselfPage = () => {
  const {t} = useI18next();
  return (
    <Layout>
      <SEO title={t("MENU.TEST-YOURSELF")} />
      <TestYourselfContent/>
    </Layout>
  )
}


export default TestYourselfPage